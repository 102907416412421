import React from 'react';
import '../../assets/css/footer.css';
import Section from '../molecules/section.js';

function Footer() {
    return (
        <div className="footer">
            © 2020 <a target="_blank" href="https://twitter.com/uehr37">uehara</a>
        </div>
    );
}

export default Footer;